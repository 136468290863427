import { Controller } from "@hotwired/stimulus"
import { getLocalTime } from '../../javascript/utilities/timeZoneHelper'

export default class extends Controller {
  static values = {
    cutoff: String,
    refreshInterval: { type: Number, default: 1000 },
    expiredMessage: { type: String, default: "Done shipping today" },
    message: { type: String, default: "${days}d ${hours}h ${minutes}m ${seconds}s" }
  }

  connect() {
    if (this.hasCutoffValue) {
      if(this.cutoffValue) {
        const today = new Date();
        const cutoffTime = this.cutoffValue
        const hour = getLocalTime(cutoffTime).hours;
        const min = getLocalTime(cutoffTime).minutes;
        this.cutoffDateTime = today.setHours(hour, min, 0, 0);

        this.update()
        this.timer = setInterval(() => {
          this.update()
        }, this.refreshIntervalValue)
      } else {
        // remove color classes if any
        this.element.parentElement.classList.remove("teal", "pink")
        // hide timer
        this.element.parentElement.firstElementChild.classList.add("hide")
        this.element.textContent = "Items in your cart"
        this.element.parentElement.classList.add('color-ez-header-middle')
      }
    } else {
      console.error("Missing data-countdown-cutoff-value attribute", this.element)
    }
  }

  disconnect() {
    this.stopTimer()
  }

  stopTimer() {
    if (this.timer) {
      clearInterval(this.timer)
    }
  }

  update() {
    let difference = this.timeDifference()

    if (difference < 0) {
      this.element.parentElement.classList.add("pink")
      this.element.textContent = this.expiredMessageValue
      this.stopTimer()
      return
    }

    let days = Math.floor(difference / (1000 * 60 * 60 * 24)).toString().padStart(2,"0")
    let hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)).toString().padStart(2,"0")
    let minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)).toString().padStart(2,"0")
    let seconds = Math.floor((difference % (1000 * 60)) / 1000).toString().padStart(2,"0")

    this.element.parentElement.classList.add("teal")
    this.element.textContent = this.messageValue
      .replace("${days}", days)
      .replace("${hours}", hours)
      .replace("${minutes}", minutes)
      .replace("${seconds}", seconds)
  }

  timeDifference() {
    return this.cutoffDateTime - new Date().getTime()
  }
}
