
import { Controller } from '@hotwired/stimulus'

const BOTTOM_BOUNDING = 340
const TOP_BOUNDING = -40
const ROOT_MARGIN = `${TOP_BOUNDING}px 0px ${BOTTOM_BOUNDING}px 0px`

export default class extends Controller {
  static values = {
    accordionId: String,
    productId: String
  }

  connect() {
    this.autoCloseProductDetail()
  }

  disconnect() {
    if (this.productDetailobserver) {
      const deliveryDetail = document.querySelector(
        `#delivery-details-${this.productIdValue}`
      )
      if (!deliveryDetail) return
      this.productDetailobserver.unobserve(deliveryDetail)
    }
  }

  toggleCollapse(collapseId, productId, isOpen = true) {
    const $accordion = $(`#${collapseId}`)
    const $productRow = $(`#product-${productId}`)
    const $deliveryDetails = $(`#delivery-details-${productId}`)

    const isExpanded = $accordion.attr('aria-expanded') === 'true'
    if (!isExpanded && isOpen) {
      $accordion.collapse('show')
      $productRow.addClass('border-blue')
      $productRow.find('.details-expansion').attr('aria-expanded', 'true')
      $deliveryDetails.find('.hover-instruction-text').addClass('collapsed')
    } else {
      $accordion.collapse('hide')
      $productRow.removeClass('border-blue')
      $productRow.find('.details-expansion').attr('aria-expanded', 'true')
      $deliveryDetails.find('.hover-instruction-text').removeClass('collapsed')
    }
  }

  onDeliveryDetailsClick(event) {
    const isValidTarget = () => {
      const $target = $(event.target)
      return !$target.is('span') && !$target.is('i')
    }

    if (!isValidTarget()) return
    this.toggleCollapse(this.accordionIdValue, this.productIdValue)
  }

  autoCloseProductDetail() {
    this.productDetailobserver = new IntersectionObserver(
      ([entry]) => {
        if (!entry.isIntersecting) {
          this.toggleCollapse(this.accordionIdValue, this.productIdValue, false)
        }
      },
      { threshold: [0], rootMargin: ROOT_MARGIN }
    )

    const deliveryDetail = document.querySelector(
      `#delivery-details-${this.productIdValue}`
    )
    this.productDetailobserver.observe(deliveryDetail)
  }

  onProductRowClick(event) {
    const isValidTarget = () => {
      const $target = $(event.target)
      if ($target.is('input') || $target.is('button') || $target.is('a')) {
        return false
      }
      if ($target.closest('div.cell').length > 0) return false
      return true
    }

    if (!isValidTarget()) return
    this.toggleCollapse(this.accordionIdValue, this.productIdValue)
  }
}
